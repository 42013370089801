import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  static targets = ['filterDestination']

  connect() {
    this.filterModal = new Modal('#filterDestinationModal', {
      keyboard: true,
      backdrop: false,
    })
  }

  showFilterOptions(event) {
    event.preventDefault();
    const path = event.target.href;
    fetch(path)
      .then(response => response.text())
      .then(html => {
        this.filterDestinationTarget.hidden = false;
        this.filterDestinationTarget.innerHTML = html;
        this.filterModal.show();
      }
    )
  }

  close(event) {
    this.filterDestinationTarget.hidden = true;
    this.filterModal.hide();
  }
}
