import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['publisherMatch', 'destinationForm', 'submitButton', 'selectAllDestination']

  enable(event) {
    this.switchSelectAllSwitchAndLabel();
    this.publisherMatchTargets.forEach((pubMatch) => {
      const companyId = pubMatch.querySelector('#company_id').value;
      const destinationElement = pubMatch.querySelector('.checkbox-destination');
      const checkboxElement = destinationElement.querySelector('input[type=checkbox]');
      const actionButton = pubMatch.querySelector('.action-button')

      if (actionButton.disabled) return

      if (checkboxElement) {
        destinationElement.removeChild(checkboxElement);
        return
      }

      var checkbox = document.createElement('input');
      checkbox.type = 'checkbox';
      checkbox.id = `publisher_company_${companyId}`;
      checkbox.name = `publisher_company_${companyId}`;
      checkbox.value = companyId;
      checkbox.className = 'form-check-input';
      checkbox.onclick = (event) => this.select(event);
      destinationElement.appendChild(checkbox);
    })
  }

  select(event) {
    const checkbox = event.target;

    this.appendCompanyDetailsToForm(checkbox);
  }

  switchAll(event) {
    const selections = document.querySelectorAll(".checkbox-destination input");
    selections.forEach((selection) => {
      selection.checked = event.target.checked;
      this.appendCompanyDetailsToForm(selection);
      this.submitButtonTarget.disabled = !event.target.checked
    })
  }

  switchSelectAllSwitchAndLabel() {
    const selectAllCheckboxElement = this.selectAllDestinationTarget.querySelector('#selectAllMatchesCheckbox');
    const selectAllLabelElement = this.selectAllDestinationTarget.querySelector('.form-check-label');

    if (selectAllCheckboxElement) {
      selectAllCheckboxElement.parentNode.removeChild(selectAllCheckboxElement);
      selectAllLabelElement.parentNode.removeChild(selectAllLabelElement);
      return
    };

    var checkbox = document.createElement('input');
    checkbox.type = 'checkbox';
    checkbox.className = 'form-check-input';
    checkbox.id = "selectAllMatchesCheckbox";
    checkbox.onclick = (event) => this.switchAll(event);
    this.selectAllDestinationTarget.appendChild(checkbox);

    var label = document.createElement('label');
    var text = document.createTextNode('All');
    label.className = 'form-check-label';
    label.setAttribute('for', 'selectAllMatchesCheckbox');
    label.appendChild(text);
    this.selectAllDestinationTarget.appendChild(label);
  }

  appendCompanyDetailsToForm(checkbox) {
    const companyId = checkbox.value;
    const identifier = `hidden_publisher_company_${companyId}`;
    const elemAllCheckbox = this.selectAllDestinationTarget.querySelector('#selectAllMatchesCheckbox');

    if (checkbox.checked) {
      var hiddenField = document.createElement('input');
      hiddenField.id = identifier;
      hiddenField.name = 'company_ids[]';
      hiddenField.value = companyId;
      hiddenField.hidden = true;
      this.destinationFormTarget.appendChild(hiddenField);
      this.submitButtonTarget.disabled = false
      const selections = Array.from(document.querySelectorAll(".checkbox-destination input"));
      if (selections.every(selection => selection.checked === true)) {
        elemAllCheckbox.checked = true;
      }
    } else {
      const elem = this.destinationFormTarget.querySelector(`#${identifier}`);
      elem.parentNode.removeChild(elem);
      elemAllCheckbox.checked = checkbox.checked;
      const selections = this.destinationFormTarget.querySelectorAll("input[name='company_ids[]']");
      if (selections.length === 0) {
        this.submitButtonTarget.disabled = true;
      }
    }
  }
}
